
import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import AlacakBelgeTuruPicker from "@/components/pickers/AlacakBelgeTuruPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IlamTipiPicker from "@/components/pickers/IlamTipiPicker.vue";
import {IlamTipi} from "@/enum/IlamTipi";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import IlamBilgileriMahkemePicker from "@/components/pickers/IlamBilgileriMahkemePicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
@Component({
  components: {
    NoterPicker,
    IlamBilgileriMahkemePicker,
    MahkemeSecim,
    IlamTipiPicker,
    AlacakBelgeTuruPicker

  }
})
export default class TercihlerIlamBilgileri extends Mixins(ObjectInputMixin) {
  @Prop() takipAltTipi!:any
  @Prop() items!: Array<any>;
  ilamTipi = IlamTipi;

  save(){
    this.takipAltTipi.ilam_bilgileri = this.localValue
    this.$http.put(`/api/v1/takip-alt-tipi/${this.$route.params.id}`,this.takipAltTipi)
    this.$emit('load');
  }


}
