import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";
import {FaizTuruEntity} from "@/entity/FaizTuruEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";

export class TakipAltTipiEntity {
    id!: number;
    isim!: string;
    borc_metni_var_mi!: boolean
    takip_tipi_id!: number;
    takip_tipi!: TakipTipiEntity;
    para_birimi_id!: number;
    para_birimi!: ParaBirimiEntity;
    faiz_turu_id!: number;
    faiz_turu!: FaizTuruEntity;
    alacakli_id!: number;
    alacakli!: AlacakliEntity;
    ilam_bilgileri!:any
    takip_alt_tip_hesap_ayar!: Array<TakipAltTipHesapAyarEntity>;
    takip_talebi_metni!: string;
    odeme_taahhudu_metni_ilk_paragraf!: string;
    odeme_taahhudu_metni_son_paragraf!: string;
    odeme_emri_metni!: string;
    faiz_aciklama_metni!: string;
    takip_sonrasi_faiz_turu_id !: number
    takip_oncesi_faiz_turu_id !: number
}
