
import {Vue, Component, Prop, Mixins} from 'vue-property-decorator';
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import {NoterEntity} from "@/entity/NoterEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker}
})
export default class IlamBilgileriMahkemePicker extends Mixins(SimpleInputMixin) {
  @Prop() LabelNew!: string | null;
  @Prop() returnId!: string | boolean;
  @Prop() returnObject!: string | boolean;
  @Prop() rules !: any;

  items: Array<object> = [];

  get label(){
    if (this.LabelNew!=null){
      return this.LabelNew;
    }else{
      return "Mahkeme";
    }
  }

  get isReturnId() {
    return this.returnId || this.returnId === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  onNoterChange(v: NoterEntity) {
    this.$emit('mahkeme-adi', v.isim);
    this.input()
  }

  mounted() {
    this.load();
  }

  load() {
    if (this.isReturnObject) {
      this.$http
          .get("/api/v1/mahkeme")
          .then((response: any) => {
            this.items = response.data;
          });
    }
  }
}
