
import {Component, Vue, Watch} from "vue-property-decorator";
import {TakipAltTipiEntity} from "@/entity/TakipAltTipiEntity";
import TakipAltTipAlanlarList from "@/components/lists/TakipAltTipAlanlarList.vue";
import TakipAltTipInfo from "@/components/infos/TakipAltTipInfo.vue";
import TahsilatDusmeSirasi from "@/components/TahsilatDusmeSirasi.vue";
import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";
import {TakipTipi} from "@/enum/TakipTipi";
import TercihlerIlamBilgileri from "@/components/TercihlerIlamBilgileri.vue";

@Component({
  computed: {
    TakipTipi() {
      return TakipTipi
    }
  },
  components: {
    TercihlerIlamBilgileri,
    TahsilatDusmeSirasi,
    TakipAltTipAlanlarList,
    TakipAltTipInfo,
  },
})
export default class TakipAltTipView extends Vue {
  item: TakipAltTipiEntity = new TakipAltTipiEntity();
  TakipTipi = TakipTipi
  mounted() {
    this.load();
  }

  @Watch('item.borc_metni_var_mi')
  changeBorcMetin(){
    this.$http.put(`/api/v1/takip-alt-tipi/${this.$route.params.id}`,this.item)
    this.load()
  }

  load() {
    this.$http.get(`/api/v1/takip-alt-tipi/${this.$route.params.id}`).then((response) => {
      this.item = response.data;
      this.item.takip_alt_tip_hesap_ayar.sort((prev: TakipAltTipHesapAyarEntity, current: TakipAltTipHesapAyarEntity) => (prev.gosterim_sirasi - current.gosterim_sirasi));
    });
  }
}
